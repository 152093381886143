.api-integration {
    padding-top: 100px;
    color: @grey;
    letter-spacing: 0.26px;
    margin-bottom: 95px;

    @media (max-width: 768px) {
        padding-top: 60px;
        margin-bottom: 50px;
    }
    

    &__title {
        font-weight: bold;
        margin-bottom: 60px;

        @media (max-width: 768px) {
            margin-bottom: 30px;
            text-align: center;
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;

        @media (max-width: 768px) {
            text-align: center;
            position: static;
        }

        img {
            max-width: 100%;

            @media (max-width: 768px) {
                margin-bottom: 40px;
            }
        }
    }

    &__text {
        font-size: 18px;

        @media (max-width: 768px) {
            text-align: center;
        }
    }

    &__btn {
        padding-top: 40px;

        @media (max-width: 768px) {
           .btn {
               margin: 0 auto;
           }
        }
    }
}