.footer {
    background-color: @blue;
    padding: 10px 0;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 992px) {
            flex-wrap: wrap;
            flex-direction: column;
            padding-top: 15px;
            padding-bottom: 25px;
        }
    }

    &__nav-list {
        display: flex;
        margin-left: -40px;

        @media (max-width: 992px) {
            flex-wrap: wrap;
            justify-content: space-around;
            margin-bottom: 25px;
            margin-top: 20px;
        }
    }

    &__nav-item {
        margin-left: 40px;

        @media (max-width: 992px) {
            margin-bottom: 15px;
        }
    }

    &__nav-link,
    &__info-link {
        color: @white;
    }

    &__call-back {
        @media (max-width: 992px) {
            margin-top: 30px;
        }
    }

    &__info-item {
        margin-bottom: 3px;

        @media (max-width: 992px) {
            margin-bottom: 10px;
        }

    }

    &__info-link {
        span {
            display: inline-block;
            margin-left: 8px;
        }
    }

}