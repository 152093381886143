.our-projects {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;

  @media (max-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media (max-width: 550px) {
    padding-top: 50px;
    padding-bottom: 50px;
    position: static;
    height: auto;
  }

  .title {
    text-align: center;
    color: @grey;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .content-box {

    .items {
      .item {
        padding-top: 60px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .image {
          width: 50%;
          @media (max-width: 992px) {
            width: 40%;
          }
          @media (max-width: 768px) {
            width: 100%;
            text-align: center;
          }
          img {
            max-width: 100%;
          }
        }
        .text {
          width: 50%;
          padding-left: 105px;

          @media (max-width: 992px) {
            width: 60%;
          }

          @media (max-width: 768px) {
            width: 100%;
            padding-left: 0;
            text-align: center;
          }

          h3 {
            padding-bottom: 52px;
          }

          p {
            padding-bottom: 20px;
            margin: 0;
          }

          .action {
            padding-top: 20px;

            @media (max-width: 768px) {
              text-align: center;
            }

            display: flex;
            align-items: center;
            flex-wrap: wrap;

            a {
              max-width: 200px;
              @media (max-width: 768px) {
                margin-left: auto;
                margin-right: auto;
              }

            }
          }

          .all-pages {
            //display: none;
            padding-top: 20px;
            ul {
              padding-top: 20px;
              padding-bottom: 20px;
              width: 100%;
              display: flex;
              justify-content: left;
              flex-wrap: wrap;

              @media (max-width: 768px) {
                justify-content: center;
              }

              li {
                width: auto;
                padding-left: 5px;
                padding-right: 5px;
                text-align: center;
                list-style-type: none;
                display: block;
                padding-top: 5px;
                padding-bottom: 5px;
                cursor: pointer;

                a {
                  padding: 12px 15px;
                  border-radius: 40px;
                  font-size: 14px;
                  background: @blue;

                  display: table;
                  margin-left: auto;
                  margin-right: auto;


                  color: @white;

                  &:hover {
                    background: @dark-blue;
                  }

                }
              }
            }
          }
        }
      }
    }
  }
}
