.section {
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap:wrap;
        position: relative;

        @media (max-width: 768px) {
            flex-direction: column;

        }
    }

    &__left,
    &__right {
        width: 50%;

        @media (max-width: 768px) {
            width: 100%;
            
        }
    }

    &__left {
        @media (max-width: 768px) {
            order: 1;
            
        }
    }

    &__right {
        @media (max-width: 768px) {
            order: 0;
            
        }
    }

    &__title {
        // font-size: 36px;
        text-align: center;
        color: @grey;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto
        // line-height: 42px;
    }

    &__text {
        text-align: center;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: @black;
        max-width: 725px;
        margin: 0 auto;

        p {
            color: @black;
        }
    }

    .action-bottom {
        text-align: center;
        padding-top: 40px;
        margin-bottom: 40px;

        .action {
            a {
                max-width: 200px;
                margin: 0 auto;
            }
        }
    }
}