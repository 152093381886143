.how-we-help {
  padding-top: 50px;
  padding-bottom: 10px;
  position: relative;

  @media (max-width: 992px) {
    padding-top: 50px;
    padding-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 10px;
  }

  @media (max-width: 550px) {
    padding-top: 50px;
    padding-bottom: 10px;
    position: static;
    height: auto;
  }

  .title {
    text-align: center;
    color: @grey;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .content-box {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;

    .items {
      .item {
        display: flex;
        align-items: center;
        padding-top: 65px;

        @media(max-width: 450px) {
          flex-wrap: wrap;
        }

        .image {

          img {
            width: 95px;
          }

          @media(max-width: 450px) {
            width: 100%;
            text-align: center;

            img {
              max-width: 100%;
            }
          }
        }

        .description {
          padding-left: 65px;

          p {
            margin: 0;
            color: #000000;
            line-height: 35px;

            @media (max-width: 550px) {
              line-height: 28px;
            }
          }

          @media(max-width: 450px) {
            width: 100%;
            text-align: center;
            padding-left: 0;
            padding-top: 30px;
          }
        }
      }
    }
  }
}
