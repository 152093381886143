.fb-resolved-tasks {

  padding-top: 50px;
  padding-bottom: 50px;
  position: static;
  background: linear-gradient(90deg, #004AFF 0%, #0081FF 125.68%);

  @media (max-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: 550px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }


  .title {
    text-align: center;
    color: @white;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    h2 {
      color: @white;
    }
  }


  .content-box {

    .rows {

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-top: 40px;

      .image {
        width: 33.333%;

        @media (max-width: 768px) {
          width: 40%;
        }

        @media (max-width: 650px) {
          width: 50%;
        }

        @media (max-width: 500px) {
          width: 100%;
          text-align: center;
        }

        img {
          @media (max-width: 650px) {
            max-width: 100%;
          }
        }
      }

      .items {
        padding-top: 30px;
        width: 66.666%;

        @media (max-width: 992px) {
          padding-top: 0;
        }

        @media (max-width: 768px) {
          width: 60%;
        }

        @media (max-width: 650px) {
          width: 50%;
        }

        @media (max-width: 500px) {
          width: 100%;
        }

        ul {
          li {
            list-style-type: none;
            font-weight: bold;
            font-size: 18px;
            line-height: 32px;
            letter-spacing: 0.264706px;
            color: #FFFFFF;
            padding-left: 20px;
            padding-right: 40px;
            position: relative;

            @media(max-width: 768px) {
              font-size: 16px;
              line-height: 28px;
            }
            @media(max-width: 650px) {
              font-size: 14px;
              line-height: 24px;
            }
            @media(max-width: 500px) {
              max-width: 350px;
              margin-left: auto;
              margin-right: auto;
            }

            &:before {
              top: 12px;
              left: 0;
              position: absolute;
              content: " ";
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: @white;
            }
          }
        }
      }

      &:nth-child(2) {
        .image{
          order: 1
        }
        .items {
          order: 0;


          @media(max-width: 500px) {
            order: 2;
            padding-top: 30px;
          }
        }
      }
    }
  }
}
