.offer-includes {
    margin-bottom: 50px;
    padding-top: 80px;
    &__list {
        padding-top: 80px;
        max-width: 820px;
        margin: 0 auto;
    }

    &__item {
        margin-bottom: 80px;
        display: flex;

        @media (max-width: 768px) {
            flex-direction: column;
            text-align: center;
            flex-wrap: wrap;
        }

        &-content {
            padding-left: 80px;
            max-width: 850px;

            @media (max-width: 768px) {
                padding-left: 0;
            }
        }

        &-img {
            @media (max-width: 768px) {
                margin-bottom: 30px;
                text-align: center;
            }
        }

        &-title {
            color: @black;
            font-size: 24px;
            line-height: 27px;
            font-weight: bold;
            margin-bottom: 18px;
        }
    }
}