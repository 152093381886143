.comfortable-work {
    padding-top: 80px;
    margin-bottom: 100px;

    &__address {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        line-height: 21px;
        color: @grey;
        margin-top: 50px;
        margin-bottom: 40px;

        @media(max-width:400px) {
            max-width: 200px;
            margin-left: auto;
            margin-right: auto;
        }

        span {
            display: inline-block;
            margin-left: 12px;
        }
    }

    &__suptitle {
        font-size: 18px;
        line-height: 21px;
        color: @black;
        font-weight: bold;
        text-align: center;

        max-width: 500px;
        margin-right: auto;
        margin-left: auto;

        margin-top: 30px;

        &:first-child {
          margin-top: 0; 
        }

        @media(max-width:550px) {
            font-size: 16px;
        }
    }

    &__list {
        max-width: 900px;
        margin: 0 auto;
        padding-top: 100px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 768px) {
            flex-direction: column;
            padding-top: 70px;
        }

        &-item {
            width: 50%;
            text-align: center;
            font-size: 18px;
            line-height: 21px;
            padding-left: 40px;
            padding-right: 40px;

            @media (max-width: 768px) {
                width: 100%;
                margin-bottom: 40px;
                padding-left: 0;
                padding-right: 0;
            }
            
        }

        &-item-img {
            margin-bottom: 20px;
        }

        &-item-text {
            color: @black;
        }
    }
}