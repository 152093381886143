.fb-landing-banner {

  padding-top: 130px;
  padding-bottom: 65px;
  position: relative;

  @media (max-width: 992px) {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  @media (max-width: 550px) {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .content-box {

    width: 100%;
    padding-left: 570px;
    position: relative;


    @media(max-width: 1100px) {
      padding-left: 500px;
    }

    @media(max-width: 1050px) {
      padding-left: 480px;
    }

    @media (max-width: 992px) {
      padding-left: 0;
      max-width: 630px;
      text-align: center;
      margin-right: auto;
      margin-left: auto;
    }

    .title {
      max-width: 630px;
      h1 {
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.264706px;
        color: @grey;
      }
    }

    .image {
      position: absolute;
      top: -150px;
      left: -250px;
      width: 800px;

      @media (max-width: 992px) {
        margin-top: 200px;
        position: relative;
        max-width: 800px;
        height: 400px;
        left: 0;
        width: 100%;
      }

      @media (max-width: 600px) {
        height: 270px;
      }

      @media (max-width: 500px) {
        height: 200px;
      }

      @media (max-width: 400px) {
        height: 150px;
      }

      .banner-1 {
        width: 100%;
        position: absolute;
        top: -50px;
        left: -200px;
        img {
          max-width: 100%;
        }
      }

      .banner-2 {
        width: 100%;
        position: absolute;
        top:0;
        left: -100px;
        img {
          max-width: 100%;
        }
      }

      .banner-3 {
        width: 100%;
        position: absolute;
        top: 50px;
        left: 0;
        img {
          max-width: 100%;
        }
      }

    }

    .description {
      padding-top: 34px;

      @media (max-width: 992px) {
        max-width: 300px;
        margin-right: auto;
        margin-left: auto;
      }
      p {
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0.264706px;
        color: @grey;
      }
    }

    .action {
      padding-top: 38px;

      @media (max-width: 992px) {
        text-align: center;
      }

      button {
        padding-left: 40px;
        padding-right: 40px;

        @media (max-width: 992px) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
