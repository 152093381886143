.for-whom {

    &__list {
        max-width: 900px;
        margin: 0 auto;
        margin-bottom: 120px;
        padding-top: 100px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
            padding-top: 70px;
        }


        &-item {
            width: 50%;
            text-align: center;
            font-size: 18px;
            line-height: 21px;

            @media (max-width: 768px) {
                width: 100%;
                margin-bottom: 40px;
            }
        }

        &-item-img {
            margin-bottom: 20px;
        }

        &-item-label {
            color: @black;
        }
    }

}