.our-goal {
  padding-top: 100px;
  padding-bottom: 50px;
  position: relative;

  @media (max-width: 992px) {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  @media (max-width: 550px) {
    padding-top: 60px;
    padding-bottom: 50px;
    position: static;
    height: auto;
  }

  .title {
    text-align: center;
    color: @grey;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .content-box {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 56px;

    @media (max-width: 800px) {
      width: 100%;
      padding-left: 70px;
      padding-right: 70px;
    }

    @media (max-width: 450px) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }

    .line {
      height: 1px;
      width: 100%;
      background-color: @grey;
    }

    .description {

      padding-top: 30px;
      padding-bottom: 30px;

      display: flex;

      .flag {

        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
          content: url("../img/landing/racing-flag.svg");
        }

        @media (max-width: 600px) {
          display: none;
        }
      }

      .text {
        width: 80%;
        @media (max-width: 600px) {
          width: 100%;
        }
        p {
          max-width: 400px;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          line-height: 35px;

          @media (max-width: 800px) {
            max-width: 300px;
          }
          @media (max-width: 550px) {
           line-height: 28px;
          }
        }
      }

    }
  }
}
