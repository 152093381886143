.our-partners {
    margin-bottom: 70px;
    &__list {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        flex-wrap: wrap;
    }

    &__img {
        text-align: center;
    }

    &__name {
        font-size: 18px;
        line-height: 21px;
        font-weight: bold;
        color: @black;
        text-align: center;

    }
}