.where-to-use {
  padding-top: 130px;
  padding-bottom: 130px;
  position: relative;

  @media (max-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (max-width: 550px) {
    padding-top: 60px;
    padding-bottom: 60px;
    position: static;
    height: auto;
  }

  .title {
    text-align: center;
    color: @grey;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .content-box {
    margin-top: 100px;
    position: relative;
    height: 475px;

    @media (max-width: 992px) {
      height: 500px;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      height: 550px;
    }


    @media(max-width: 650px) {
      padding-left: 15px;
      padding-right: 15px;
      height: auto;
    }


    .food {
      position: absolute;
      top: 0;
      left: 100px;

      @media(max-width: 768px) {
        position: relative;
        top:0;
        left: 0;
        width: 50%;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      @media(max-width: 650px) {
        width: 100%;
        max-width: 300px;
        margin-right: auto;
        margin-left: auto;
      }


      .text {
        font-weight: bold;
        font-size: 18px;
        line-height: normal;
        letter-spacing: 0.264706px;
        color: #000000;
        position: absolute;
        left: 130px;
        top:0;
        width: auto;
        white-space: nowrap;

        @media(max-width: 768px) {
          white-space: initial;
        }
      }
    }

    .sell {
      position: absolute;
      top: 150px;
      left: 230px;


      @media(max-width: 768px) {
        position: relative;
        top:0;
        left: 0;
        width: 50%;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      @media(max-width: 650px) {
        width: 100%;
        max-width: 300px;
        margin-right: auto;
        margin-left: auto;
      }

      .text {
        font-weight: bold;
        font-size: 18px;
        line-height: normal;
        letter-spacing: 0.264706px;
        color: #000000;
        position: absolute;
        left: 140px;
        top:0;
        width: auto;
        white-space: nowrap;

        @media(max-width: 768px) {
          white-space: initial;
        }
      }
    }

    .presentation {
      position: absolute;
      top: 50px;
      left: 600px;


      @media(max-width: 992px) {
        top:-30px;
        left: 500px;
      }

      @media(max-width: 850px) {
        top:-30px;
        left: 450px;
      }


      @media(max-width: 768px) {
        position: relative;
        top:0;
        left: 0;
        width: 50%;
        margin-top: 30px;
        margin-bottom: 30px;
      }


      @media(max-width: 650px) {
        width: 100%;
        max-width: 300px;
        margin-right: auto;
        margin-left: auto;
      }

      .text {
        font-weight: bold;
        font-size: 18px;
        line-height: normal;
        letter-spacing: 0.264706px;
        color: #000000;
        position: absolute;
        left: 150px;
        top:0;
        width: auto;
        white-space: nowrap;

        @media(max-width: 768px) {
          white-space: initial;
        }
      }
    }

    .services {
      position: absolute;
      top: 150px;
      left: 850px;

      @media (max-width: 1150px) {
        top: 250px;
        left: 750px;
      }

      @media (max-width: 1050px) {
        top: 300px;
        left: 500px;
      }


      @media(max-width: 850px) {
        left: 450px;
      }


      @media(max-width: 768px) {
        position: relative;
        top:0;
        left: 0;
        width: 50%;
        margin-top: 30px;
        margin-bottom: 30px;
      }


      @media(max-width: 650px) {
        width: 100%;
        max-width: 300px;
        margin-right: auto;
        margin-left: auto;
      }

      .text {
        font-weight: bold;
        font-size: 18px;
        line-height: normal;
        letter-spacing: 0.264706px;
        color: #000000;
        position: absolute;
        left: 140px;
        top:0;
        width: auto;
        white-space: nowrap;

        @media(max-width: 768px) {
          white-space: initial;
        }
      }
    }

    .entertainment {
      position: absolute;
      top: 330px;
      left: 0;


      @media(max-width: 768px) {
        position: relative;
        top:0;
        left: 0;
        width: 50%;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      @media(max-width: 650px) {
        width: 100%;
        max-width: 300px;
        margin-right: auto;
        margin-left: auto;
      }

      .text {
        font-weight: bold;
        font-size: 18px;
        line-height: normal;
        letter-spacing: 0.264706px;
        color: #000000;
        position: absolute;
        left: 130px;
        top:0;
        width: auto;
        white-space: nowrap;

        @media(max-width: 768px) {
          white-space: initial;
        }
      }
    }

    .description {
      position: absolute;
      top:350px;
      left: 400px;
      width: 450px;

      @media (max-width: 1150px) {
        top: 380px;
        left: 240px;
      }

      @media (max-width: 1050px) {
        top: 450px;
        left: 250px;
      }

      @media (max-width: 992px) {
        left: 0;
        width: 100%;
        text-align: center;
      }


      @media(max-width: 768px) {
        position: relative;
        left: -50px;
        top:0;
        width: 50%;
        margin-top: 30px;
        margin-bottom: 30px;
      }


      @media(max-width: 650px) {
        position: static;
        width: 100%;
        max-width: 300px;
        margin-right: auto;
        margin-left: auto;
      }

      p {
        text-align: center;
        letter-spacing: 0.264706px;
        color: #000000;
        line-height: 35px;

        @media (max-width: 992px) {
          max-width: 450px;
          margin-right: auto;
          margin-left: auto;
        }

        @media (max-width: 550px) {
          line-height: 28px;
        }
      }
    }
  }
}
