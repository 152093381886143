.systems-integration {
    padding-top: 50px;
    padding-bottom: 50px;
    &__list {
        padding: 0 30px;
        padding-top: 80px;
        max-width: 1030px;
        margin: 0 auto;

        @media (max-width: 768px) {
            padding: 80px 0 0 0;
        }
    }

    &__item {
        margin-bottom: 80px;
        display: flex;

        @media (max-width: 768px) {
            flex-direction: column;
            text-align: center;
            margin-bottom: 60px; 
        }

        &-img {
            width: 100%;
            max-width: 150px;
            text-align:center;
            @media (max-width: 768px) {
                margin-bottom: 30px;
                text-align: center;
                max-width: 100%;
            }
        }

        &-content {
            padding-left: 40px;
            max-width: 850px;

            @media (max-width: 768px) {
                padding-left: 0;
            }
        }

        &-title {
            color: @black;
            font-size: 18px;
            line-height: 21px;
            font-weight: bold;
            margin-bottom: 18px;
        }
    }
}