.fb-header {



  &.mobile {
    .over-flow {
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }



  .main-menu {

    height: 100px;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;


    .brand {
      width: 10%;
      @media (max-width: 768px) {
        width: 15%;

        img {
          width: 45px;
        }
      }
    }

    .links {
      width: 50%;

      @media (max-width: 992px) {
        width: 30%;
      }
      @media (max-width: 768px) {
        width: 0%;
        display: none;
      }


      ul {
        margin: 0;
        padding: 0;

        li {
          list-style-type: none;
          font-weight: 500;
          line-height: normal;
          font-size: 14px;
          text-transform: capitalize;
          padding-left: 24px;
          padding-right: 24px;
          display: inline-block;

          a {
            color: @grey;
            &:hover {
              color: @light-blue;
            }
          }

          &.active {
            a {
              color: @light-blue;
            }
          }
        }
      }
    }

    .info {
      width: 20%;

      @media (max-width: 992px) {
        width: 30%;
      }
      @media (max-width: 768px) {
        width: 40%;
      }
      @media (max-width: 500px) {
        width: 80%;
        text-align: center;

        .contacts {
          max-width: 190px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .contacts {
        text-align: left;
        .contact-item {
          font-weight: 500;
          line-height: normal;
          font-size: 14px;
          letter-spacing: 0.264706px;
          color: @grey;
          width: 100%;
          text-align: left;

          padding-top: 6px;

          &:first-child {
            padding-top: 0;
          }

          svg {
            margin-right: 10px;
          }
        }
      }
    }

    .action {
      width: 20%;
      text-align: right;

      .btn {
        margin-left: auto;
        margin-right: 0;
      }

      @media (max-width: 992px) {
        width: 30%;
      }

      @media (max-width: 768px) {
        width: 40%;
      }
      @media (max-width: 500px) {
        display: none;
      }
    }

    .mobile-menu {
      display: none;

      @media (max-width: 992px) {
        //display: block;
      }
    }
  }
}
